<template>
  <v-card class="mt-4">
    <v-card-title>
      <v-row>
        <v-col cols="12">
          Withdrawal History
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="mb-4">
        <v-col class="col-md-3">
          <refresh-button
            :loading="isWithdrawalHistoryRefreshing"
            @click="refreshTable()"
          />
        </v-col>
        <v-col class="col-md-6">
          <table-filter
            :table-name="'withdrawal-history'"
            :default-date-picker-date="defaultDatePickerDate"
            @filter="applyFilters"
          />
        </v-col>
        <v-col class="col-md-3 text-right">
          <export-button
            :title="constructExportTableTitle()"
            :sub-title="'Total Amount: Ksh ' + $options.filters.formatCurrency(withdrawalHistorySum)"
            :headers="exportTableHeaders"
            :data-endpoint="constructCompleteWithdrawalHistoryUrl()"
            :orientation="'landscape'"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="items.length > 0">
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.amount }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="statusClass(item.transaction_status)"
                >
                  {{ item.transaction_status | withdrawalStatusLabel }}
                </v-chip>
              </td>
              <td>{{ item.account_balance_before }}</td>
              <td>{{ item.account_balance_after }}</td>
              <td>{{ item.withdrawal_method }}</td>
              <td>{{ item.withdrawal_value }}</td>
              <td>{{ item.created_at | formatDate }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import TableFilter from '../partials/table-filter/index.vue'
import ExportButton from '../partials/ExportButton.vue'

export default {
  components: {
    DataTablePagination,
    RefreshButton,
    TableFilter,
    ExportButton,
  },
  filters: {
    withdrawalStatusLabel(status) {
      const statusLabels = {
        0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Failed',
      }

      return statusLabels[status] || 'Unknown'
    },
    formatDate(value) {
      if (!value) return ''
      const date = new Date(value)

      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    },
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    resellerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'transaction_status' },
        { text: 'Account Balance Before', value: 'account_balance_before', sortable: false },
        { text: 'Account Balance After', value: 'account_balance_after', sortable: false },
        { text: 'Withdrawal Method', value: 'withdrawal_method', sortable: false },
        { text: 'Destination Number', value: 'withdrawal_value', sortable: false },
        { text: 'Date', value: 'created_at' },
      ],
      loading: false,
      isWithdrawalHistoryRefreshing: false,
      sortBy: ['created_at'],
      sortDesc: [true],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      defaultDatePickerDate: 'Last 30 days',
      exportTableHeaders: [],
      withdrawalHistorySum: '',
    }
  },
  computed: {
    shouldRefreshTable() {
      return `${this.sortBy}|${this.sortDesc}|${this.pagination.page}|${this.pagination.itemsPerPage}|${this.resellerId}`
    },
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
  },
  watch: {
    shouldRefreshTable() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
    this.$on('refresh-table', this.fetchData)
  },
  beforeDestroy() {
    this.$off('refresh-table')
  },
  methods: {
    fetchData() {
      this.loading = true
      this.isWithdrawalHistoryRefreshing = true
      const requestUrl = this.constructCompleteWithdrawalHistoryUrl()

      axios
        .get(requestUrl)
        .then(response => {
          const responseData = response.data

          // Update withdrawal history sum
          this.withdrawalHistorySum = responseData.sum // Already formatted from backend

          // Handle pagination case
          if (responseData.data.hasOwnProperty('data')) {
            this.items = responseData.data.data
            this.pagination.page = responseData.data.current_page
            this.pagination.pageCount = responseData.data.last_page
            this.pagination.itemsPerPage = responseData.data.per_page
          } else {
            // Handle export case (no pagination)
            this.items = responseData.data
          }
        })
        .catch(error => {
          console.error(error)
          this.$notification.error(error.response?.data?.message || 'An error occurred while fetching withdrawal history')
        })
        .finally(() => {
          this.loading = false
          this.isWithdrawalHistoryRefreshing = false
        })
    },
    constructExportTableHeaders() {
      this.headers.forEach(element => {
        this.exportTableHeaders.push({ title: element.text, dataKey: element.value })
      })
    },
    constructExportTableTitle() {
      return `Withdrawal history from ${this.filters.dates.fromDate} to ${this.filters.dates.toDate}`
    },
    constructCompleteWithdrawalHistoryUrl() {
      let requestUrl = this.url || ''

      if (!requestUrl) {
        console.error('Error: requestUrl is undefined or empty.')

        return ''
      }

      // Append resellerId if it exists
      if (this.resellerId) {
        requestUrl += `/${this.resellerId}`
      }

      // Ensure filters exist before appending query parameters
      const fromDate = this.filters?.dates?.fromDate || ''
      const toDate = this.filters?.dates?.toDate || ''
      const sortBy = this.sortBy?.[0] || 'id' // Default to 'id' if undefined
      const sortOrder = this.sortOrder || 'asc'
      const search = this.search || ''
      const searchFilter = this.activeSearchFilter || ''
      const page = this.pagination?.page || 1
      const perPage = this.pagination?.itemsPerPage || 10

      requestUrl += `?fromDate=${encodeURIComponent(fromDate)}`
    + `&toDate=${encodeURIComponent(toDate)}`
    + `&sortBy=${encodeURIComponent(sortBy)}`
    + `&sortOrder=${encodeURIComponent(sortOrder)}`
    + `&search=${encodeURIComponent(search)}`
    + `&search_filter=${encodeURIComponent(searchFilter)}`
    + `&page=${page}`
    + `&perPage=${perPage}`

      return requestUrl
    },
    applyFilters(filters) {
      this.filters = filters
      this.fetchData()
    },
    refreshTable() {
      this.fetchData()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchData()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.fetchData()
    },
    statusClass(status) {
      return {
        pending: status === 0,
        approved: status === 1,
        rejected: status === 2,
        failed: status === 3,
      }
    },
  },
}
</script>

<style scoped>
.v-chip.rejected {
  background: #f44336;
}
.v-chip.failed {
  background: #f44336;
}
.v-chip.approved {
  background: #4caf50;
}
.v-chip.pending {
  background: #ffa726;
}
</style>
