<template>
  <v-card class="mt-4">
    <v-card-title class="text-center justify-center">
      Withdraw
    </v-card-title>

    <v-card-text>
      <p>
        Withdraw from
        <span v-if="resellerName"><b>{{ resellerName }}'s </b></span><span v-else> your </span> wallet.
        <span v-if="walletBalance"> Current balance: <b>Ksh {{ walletBalance }} </b></span>
      </p>
      <v-form
        v-model="isFormValid"
        @submit.prevent="withdraw"
      >
        <!-- Amount Field -->
        <v-text-field
          v-model="form.amount"
          label="Amount"
          type="number"
          placeholder="Ksh 100"
          :rules="numberRules"
          :error="form.errors.has('amount')"
          :error-messages="form.errors.get('amount')"
          outlined
        />

        <v-btn
          color="primary"
          class="mt-4"
          type="submit"
          :loading="form.busy"
          block
        >
          Withdraw
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'

export default {
  name: 'WithdrawalForm',
  mixins: [validationRules],
  props: {
    phoneNumber: {
      type: [String, Number],
      default: null,
    },
    resellerId: {
      type: String,
      default: null,
    },
    resellerName: {
      type: String,
      default: null,
    },
    walletBalance: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isFormValid: true,
      form: new Form({
        amount: '',
      }),
    }
  },
  methods: {
    withdraw() {
      if (this.isFormValid) {
        const payload = {
          withdraw_to: this.form.withdraw_to,
          withdraw_to_value: this.form.withdraw_to_value,
          amount: this.form.amount,
        }

        // Determine the endpoint dynamically based on resellerId
        const endpoint = this.resellerId ? `reseller-withdraw/${this.resellerId}` : 'reseller/withdraw'

        this.form.post(endpoint, payload)
          .then(response => {
            this.$notification.success(response.data.message)
            this.$emit('balanceUpdated') // Notify parent to refresh balance
            this.form.reset() // Clear the form after a successful withdrawal
            this.form.withdraw_to = 'phone_number' // Reset to default withdraw_to
          })
          .catch(error => {
            this.$notification.error(error.response.data.message || 'An error occurred during withdrawal')
          })
      }
    },
  },
}
</script>
